import { useBreakpoints } from '@vueuse/core'

/**
 * Breakpoint <script> and <template> helpers
 */

export const breakpoints = useBreakpoints({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1400
})

export const xsAndUp = breakpoints.greaterOrEqual('xs')
export const smAndUp = breakpoints.greaterOrEqual('sm')
export const mdAndUp = breakpoints.greaterOrEqual('md')
export const lgAndUp = breakpoints.greaterOrEqual('lg')
export const xlAndUp = breakpoints.greaterOrEqual('xl')
