import { defineStore } from 'pinia'
import { STORE_ID } from '@/constants/store'
import type { OrderingState as State } from '@/types/store'

const initialState = (): State => ({
  cartItemCount: 0,
  user: {
    isSignedIn: false,
    tags: {}
  }
})

/**
 * @description Store for ordering app state values
 */
const useOrderingStore = defineStore(STORE_ID.ORDERING, {
  state: initialState,
  persist: {
    paths: ['user']
  }
})

export default useOrderingStore
